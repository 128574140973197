<template>
  <CRow class="justify-content-center p-5">
    <CCol md="9 d-flex flex-column">
      <h2 class="text-center mb-3 mt-0">Staff use only</h2>
      <InputField
        data-test-id="student-active-pass-pin"
        v-model="state.teacherPin"
        placeholder="Enter your PIN"
        :invalid-feedback="
          errors.teacherPin ||
          (state.responseMessageRoomApproval &&
            state.responseMessageRoomApproval.message)
        "
        input-type="password"
        autocomplete="off"
        autocorrect="off"
        spellcheck="false"
        @input="state.responseMessageRoomApproval = null"
      >
      </InputField>

      <InputField
        max-chars="250"
        max-length="250"
        v-model="state.teacherNote"
        class="w-full mt-2"
        type="textarea"
        placeholder="Note..."
        @input="state.responseMessageRoomApproval = null"
      >
      </InputField>

      <div
        class="d-flex flex-wrap justify-items-start h-100 w-100 mt-3 stuff-only-popup-buttons"
      >
        <button
          data-test-id="student-active-pass-approve"
          v-if="currentStatus == 'waiting' && !activePass.parent"
          class="btn btn-comfirm custom-w-h-btn m-2 p-1"
          @click="updatePassStuff(state.actions.approved)"
        >
          APPROVE
        </button>

        <button
          v-if="
            currentStatus == 'approved' &&
            isLayoverPass &&
            !(activePass.parent && activePass.parent.arrived_at)
          "
          class="btn btn-comfirm custom-w-h-btn m-2 p-1"
          @click="updatePassStuff(state.actions.arrived)"
        >
          ARRIVED
        </button>

        <button
          data-test-id="student-active-pass-in-out"
          v-if="
            isLayoverPass && !activePass.parent && currentStatus == 'approved'
          "
          class="btn btn-comfirm custom-w-h-btn m-2 p-1"
          @click="updatePassStuff(state.actions.returning, 'inout')"
        >
          IN/OUT
        </button>

        <button
          data-test-id="student-active-pass-returning"
          v-if="
            !activePass.approved_by &&
            activePass.parent &&
            currentStatus == 'approved'
          "
          class="btn btn-comfirm custom-w-h-btn m-2 p-1"
          @click="updatePassStuff(state.actions.returning, 'return')"
        >
          RETURNING
        </button>

        <button
          data-test-id="student-active-pass-end"
          v-if="!isLayoverPass && currentStatus == 'approved'"
          class="btn btn-delete custom-w-h-btn m-2 p-1"
          @click="updatePassStuff(state.actions.end)"
        >
          <span class="flaticon-check me-2" />
          END/KEEP
        </button>
        <button
          v-if="
            isLayoverPass && currentStatus == 'approved' && activePass.parent
          "
          class="btn btn-delete custom-w-h-btn m-2 p-1"
          @click="updatePassStuff(state.actions.end)"
          data-test-id="student-active-pass-end"
        >
          <span class="flaticon-check me-2" />
          END
        </button>
        <button
          v-if="
            isLayoverPass && currentStatus == 'approved' && !activePass.parent
          "
          class="btn btn-delete custom-w-h-btn m-2 p-1"
          data-test-id="student-active-pass-end"
          @click="updatePassStuff(state.actions.end)"
        >
          <span class="flaticon-check me-2" />
          END/KEEP
        </button>

        <button
          v-if="currentStatus == 'waiting'"
          class="btn btn-delete custom-w-h-btn m-2 p-1"
          @click="updatePassStuff(state.actions.cancel)"
        >
          <span class="flaticon-cancel me-2" /> CANCEL
        </button>

        <button
          v-if="currentStatus == 'waiting' && !activePass.parent"
          class="d-flex text-start align-items-center btn btn-cencel p-0 custom-w-h-btn m-2 border-1 border-secondary"
          @click="closeStuffModal()"
        >
          <span class="flaticon-forbidden ms-1 me-2 text-black" />
          CLOSE
        </button>
      </div>
    </CCol>
  </CRow>
</template>

<script>
import { reactive, computed } from "vue"
import { useStore } from "vuex"
import { useVuelidate } from "@vuelidate/core"
import { helpers, required, maxLength, minLength } from "@vuelidate/validators"
import InputField from "@/v3components/shared/Form/InputField"
import { useRouter } from "vue-router"

export default {
  name: "AdultAptPassActionModal",
  components: { InputField },
  props: {
    activePass: {
      type: Object,
      required: true
    },
    currentStatus: {
      type: String,
      required: true
    },
    isLayoverPass: {
      type: Boolean,
      required: true
    }
  },
  emits: ["cancel"],
  setup(props, { emit }) {
    const store = useStore()

    const router = useRouter()

    const state = reactive({
      teacherPin: "",
      teacherNote: "",
      responseMessageRoomApproval: null,
      actions: {
        approved: 1,
        end: 2,
        arrived: 3,
        returning: 4,
        cancel: 5
      }
    })

    const validationMessages = {
      required: "This field is required",
      maxLength: "The pin may not be greater than 6 characters",
      minLength: "The pin should be at least 4 characters"
    }

    const validations = {
      teacherPin: {
        minLength: helpers.withMessage(
          validationMessages.minLength,
          minLength(4)
        ),
        maxLength: helpers.withMessage(
          validationMessages.maxLength,
          maxLength(6)
        ),
        required: helpers.withMessage(validationMessages.required, required)
      }
    }

    const v$ = useVuelidate(validations, state)

    const isValid = computed(() => !v$.value.$invalid)

    const errors = computed(() => {
      const errorObj = {}
      v$.value.$errors.forEach((err) => {
        errorObj[err.$property] = err.$message
      })
      return errorObj
    })

    const updatePassStuff = (argAction, returnType) => {
      if (isValid.value) {
        state.updatePassHistory = false
        const elem = {
          id: props.activePass.id,
          data: {
            teacher_pin: state.teacherPin,
            approval_message: state.teacherNote,
            action: argAction
          }
        }
        if (returnType) {
          elem.data.return_type = returnType
        }

        store
          .dispatch("passes/updatePass", elem)
          .then(() => {
            state.responseMessageRoomApproval = {
              type: "success",
              message: "Successfully updated."
            }
            v$.value.$reset()
            closeStuffModal()
            store.commit("authentication/RESET_PIN_ATTEMPTS_COUNT")
          })
          .catch((err) => {
            state.responseMessageRoomApproval = {
              type: "danger",
              message: err.response.data.message
            }
            const logout = err.response.data.data.logout
            if (logout) {
              store
                .dispatch("authentication/getUserDetails", {
                  clearCacheEntry: true
                })
                .then(() => {
                  window.location.reload()
                })
            } else if (
              err &&
              err.response &&
              err.response.status &&
              err.response.status === 423
            ) {
              store.commit("authentication/SET_USER_IS_LOCKED", true)
              router.push("/locked")
            }
          })
      } else {
        v$.value.$touch()
      }
    }

    const closeStuffModal = () => {
      emit("cancel")
    }

    return {
      state,
      updatePassStuff,
      errors,
      closeStuffModal
    }
  }
}
</script>

<style></style>
