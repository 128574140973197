<template>
  <CContainer class="out-of-office-form">
    <CRow class="justify-content-center">
      <CCol md="12" class="text-start px-0">
        <CForm class="mb-2 m-auto" @submit.prevent>
          <Label required> User </Label>
          <LazyLoadSelect
            class="mb-3"
            :invalid-feedback="errors.unavailable"
            :is-multiple="false"
            :selected="state.form.unavailable"
            :type="
              userRole === 'staff' || userRole === 'teacher'
                ? 'my_assigned'
                : 'combined'
            "
            placeholder="Select teacher / location"
            :default="defaultUnavailable"
            :prepend-items="[defaultUnavailable]"
            @changed="
              (value) => {
                state.form.unavailable = value
              }
            "
          >
          </LazyLoadSelect>
          <Label> Reason </Label>
          <InputField
            v-model="state.form.reason"
            :rows="4"
            class="w-full"
            type="textarea"
            :class="{ 'is-invalid': state.reqResponse.errors['reason'] }"
            placeholder="Reason..."
          />
          <Label> Select preset </Label>
          <div
            class="v3presets-options-container v3presets d-flex align-items-center mb-3 flex-wrap"
          >
            <div class="d-flex m-0">
              <div
                v-for="(option, index) in state.presetsOptions"
                :key="index"
                class="me-1 me-sm-3"
                @click="changePresetsTime(option)"
              >
                <div
                  style="width: auto"
                  class="v3presets-box px-2"
                  :class="{
                    active:
                      state.form.presetsTime &&
                      state.form.presetsTime.value === option.value
                  }"
                >
                  {{ option.label }}
                </div>
              </div>
            </div>
          </div>

          <CRow>
            <CCol sm="4" class="pe-3 pe-sm-2">
              <Label required> Start date </Label>
              <CustomDatePicker
                v-model="state.form.start_date"
                :masks="{ L: 'MM/DD/YYYY' }"
                :invalid-feedback="errors.start_date"
                class="cs-date-picker"
                placeholder="Select start date"
                :popover="{ visibility: 'click' }"
                :min-date="today"
                :max-date="maxDate"
                @update:model-value="formatMontlyWeekDay"
              >
              </CustomDatePicker>
            </CCol>
            <CCol sm="4" class="pe-3 pe-sm-2">
              <Label required> Start time </Label>

              <CustomTimePicker
                format="HH:mm"
                v-model="state.form.start_time"
                :invalid-feedback="errors.start_time"
                inner-label="Start Time:"
                @change="calculateAndSetEndTime(state.form.start_time)"
              >
              </CustomTimePicker>
            </CCol>
            <CCol sm="4" class="ps-3 ps-sm-2 mb-3">
              <Label required> End time </Label>

              <CustomTimePicker
                format="HH:mm"
                v-model="state.form.end_time"
                :invalid-feedback="errors.end_time"
                inner-label="End Time:"
              >
              </CustomTimePicker>
            </CCol>
          </CRow>

          <CRow>
            <CCol sm="12" class="pe-3 pe-md-2">
              <Label> Repeat </Label>
              <CustomSelect
                v-model="state.form.recurrence_type"
                :options="state.repeatOptions"
                :invalid-feedback="errors.recurrence_days"
                :clearable="false"
                :close-on-select="true"
                label="label"
                :reduce="(item) => item.value"
                @update:model-value="resetRepaetOptions"
              >
                <template #content>
                  <CRow>
                    <CCol
                      v-if="state.form.recurrence_type === 'weekly'"
                      sm="12"
                      class="pe-3 pe-md-2"
                    >
                      <div
                        class="v3weekdays-options-container pt-0 pt-sm-2 pb-sm-2 d-flex justify-content-between"
                      >
                        <div
                          class="d-flex flex-row ms-2 me-2"
                          v-for="(day, index) in state.weekDays"
                          :key="index"
                        >
                          <div
                            class="v3weekday-box"
                            :class="{
                              active: state.form.recurrence_days.find(
                                (el) => el === day.value
                              )
                            }"
                            @click="setWeekDay(day.value)"
                          >
                            <i
                              v-if="
                                state.form.recurrence_days.includes(day.value)
                              "
                              class="ri-check-line check-icon"
                            ></i>
                          </div>
                          <div class="ms-1">{{ day.label }}</div>
                        </div>
                      </div>
                    </CCol>
                  </CRow>
                </template>
              </CustomSelect>
            </CCol>
          </CRow>

          <CRow class="mt-4">
            <CCol sm="12" class="pe-3 pe-md-2">
              <Label v-if="state.form.recurrence_type" required> End on </Label>
              <CustomDatePicker
                v-if="state.form.recurrence_type"
                v-model="state.form.recurrence_end_at"
                :invalid-feedback="errors.recurrence_end_at"
                :masks="{ L: 'MM/DD/YYYY' }"
                input-class="w-full"
                placeholder="End date"
                :min-date="state.form.start_date"
                :max-date="maxDate"
              >
              </CustomDatePicker>
            </CCol>
          </CRow>

          <CAlert
            v-if="state.reqResponse.message"
            class="mt-4 w-100"
            :color="state.reqResponse.type"
            :show="!!state.reqResponse.message"
          >
            {{ state.reqResponse.message }}
          </CAlert>

          <div
            class="d-flex align-items-center justify-content-center mt-4 text-center"
          >
            <LoadingButton
              v-if="!props.isHeaderCreateButton"
              class="me-3 px-4"
              @click="cancelEdit"
              rounded
              >Cancel</LoadingButton
            >
            <LoadingButton
              @click="submit(!props.editablePass)"
              class="px-4 me-3"
              solid
              rounded
              data-test-id="ooo-create-btn"
              >{{ props.editablePass ? "Update" : "Create" }}</LoadingButton
            >
            <router-link
              v-if="props.isHeaderCreateButton"
              class="text-graident-blue d-flex flex-row-reverse"
              to="/out-of-office"
            >
              <div class="d-flex align-items-center" @click="goToFullMenu()">
                <img
                  width="18"
                  class="m-0 me-2"
                  src="@/assets/images/icons/gradient-link.png"
                />
                <span class="text-graident-blue"
                  ><span class="text-graident-blue">Go to full menu</span></span
                >
              </div>
            </router-link>
          </div>
        </CForm>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import useVuelidate from "@vuelidate/core"
import Label from "@/v3components/shared/Form/Label"
import InputField from "@/v3components/shared/Form/InputField"
import CustomTimePicker from "@/v3components/shared/Form/CustomTimePicker"
import CustomDatePicker from "@/v3components/shared/Form/CustomDatePicker"
import CustomSelect from "@/v3components/shared/Form/CustomSelect.vue"
import LoadingButton from "@/v3components/shared/Buttons/LoadingButton"
import helpersJS from "../../helpers/index"
import { helpers, required, requiredIf } from "@vuelidate/validators"
import moment from "moment-timezone"
import LazyLoadSelect from "@/v3components/shared/Form/LazyLoadSelect.vue"
import { useStore } from "vuex"
import { reactive, computed, onMounted, inject } from "vue"

export default {
  name: "OutOfOfficeForm",
  components: {
    LazyLoadSelect,
    Label,
    InputField,
    CustomTimePicker,
    CustomDatePicker,
    CustomSelect,
    LoadingButton
  },
  props: {
    editablePass: {
      type: Object,
      default: null
    },
    isInView: {
      type: Boolean,
      default: false
    },
    isHeaderCreateButton: {
      type: Boolean,
      default: false
    }
  },
  emits: ["outOfOfficeCreated", "cancel"],
  setup(props, { emit }) {
    const store = useStore()
    const modal = inject("modal")

    const state = reactive({
      isLoading: false,
      form: {
        unavailable: null,
        start_date: moment().format("MMM DD YYYY HH:mm"),
        start_time: moment().format("HH:mm"),
        end_time: null,
        reason: "",
        recurrence_type: null,
        recurrence_days: [],
        recurrence_end_at: null,
        presetsTime: null
      },
      presetsOptions: [
        {
          label: "30 min",
          value: 30
        },
        {
          label: "1 hr",
          value: 60
        },
        {
          label: "1.5 hr",
          value: 90
        },
        {
          label: "2 hr",
          value: 120
        }
      ],
      repeatOptions: [
        {
          label: "No Repetition",
          value: null
        },
        {
          label: "Daily",
          value: "daily"
        },
        {
          label: "Weekly",
          value: "weekly"
        },
        {
          label: "Monthly",
          value: "monthly"
        }
      ],
      weekDays: [
        {
          label: "Mo",
          value: "Monday"
        },
        {
          label: "Tu",
          value: "Tuesday"
        },
        {
          label: "We",
          value: "Wednesday"
        },
        {
          label: "Th",
          value: "Thursday"
        },
        {
          label: "Fr",
          value: "Friday"
        },
        {
          label: "Sa",
          value: "Saturday"
        },
        {
          label: "Su",
          value: "Sunday"
        }
      ],
      reqResponse: {
        type: "",
        message: "",
        errors: {}
      }
    })

    const validationMessages = {
      required: "This field is required",
      minValue: "End time must be greater than start time",
      pastTime: "Start time cannot be in the past"
    }

    const validations = {
      form: {
        unavailable: {
          required: helpers.withMessage(validationMessages.required, required)
        },
        start_date: {
          required: helpers.withMessage(validationMessages.required, required)
        },
        start_time: {
          required: helpers.withMessage(validationMessages.required, required),
          minValue: helpers.withMessage(
            validationMessages.pastTime,
            (value) => {
              const now = moment()
              const timeInThePast =
                helpersJS.currTzDate(state.form.start_date) ==
                  now.format("MM/DD/YYYY") && value < now.format("HH:mm")
              return !timeInThePast
            }
          )
        },
        end_time: {
          required: helpers.withMessage(validationMessages.required, required),
          minValue: helpers.withMessage(
            validationMessages.minValue,
            (value) => {
              return value > state.form.start_time
            }
          )
        },
        recurrence_type: {},
        recurrence_end_at: {
          required: helpers.withMessage(
            validationMessages.required,
            requiredIf(() => {
              return state.form.recurrence_type !== null
            })
          )
        },
        recurrence_days: {
          required: helpers.withMessage(
            validationMessages.required,
            requiredIf(() => {
              return (
                state.form.recurrence_type &&
                state.form.recurrence_type === "weekly"
              )
            })
          )
        }
      }
    }
    const v$ = useVuelidate(validations.form, state.form)

    const isValid = computed(() => !v$.value.$invalid)

    const errors = computed(() => {
      const errorObj = {}
      v$.value.$errors.forEach((err) => {
        errorObj[err.$property] = err.$message
      })

      return errorObj
    })

    const userRole = computed(() => store.getters["authentication/getUserRole"])

    const currentUser = computed(() => store.getters["authentication/user"])

    const defaultUnavailable = computed(() => {
      return {
        label: "My leave",
        value: {
          id: currentUser.value.id,
          type: "App\\Models\\User"
        }
      }
    })

    const today = computed(() => moment().format("MMM DD YYYY HH:mm"))

    const maxDate = computed(() => helpersJS.maxSchoolYearDate())

    onMounted(() => {
      state.form.recurrence_type = state.repeatOptions[0].value
      state.form.presetsTime = state.presetsOptions[1]
      calculateAndSetEndTime(state.form.start_time)
      formatMontlyWeekDay(state.form.start_date)
      if (props.editablePass) {
        setFormByPassRecord(props.editablePass)
      }
    })

    const submit = (isCreate) => {
      resetResponseMessages()
      if (state.form.start_time < moment().format("HH:mm")) {
        const temp = state.form.start_date
        state.form.start_date = 0
        state.form.start_date = temp
      }
      if (isValid.value) {
        const unavailable = {
          unavailable_id: state.form.unavailable
            ? state.form.unavailable.value.id
            : null,
          unavailable_type: state.form.unavailable
            ? state.form.unavailable.value.type
            : null,
          from_date: helpersJS.currTzDateTime(
            state.form.start_date,
            state.form.start_time
          ),
          from_time: state.form.start_time,
          to_date: helpersJS.currTzDateTime(
            state.form.start_date,
            state.form.end_time
          ),
          to_time: state.form.end_time,
          comment: state.form.reason,
          recurrence_type: state.form.recurrence_type, //daily,weekly,monthly
          recurrence_days: JSON.stringify({ days: state.form.recurrence_days }), //{"days": ["Monday","Friday"]}
          recurrence_end_at: state.form.recurrence_end_at
            ? helpersJS.currTzDateTime(state.form.recurrence_end_at)
            : "",
          recurrence_week: state.form.recurrence_week // {"1":"Friday"}
        }
        if (isCreate) {
          createOutOfOfficePass(unavailable)
        } else {
          editOutOfOffice(props.editablePass.id, unavailable)
        }
      } else {
        v$.value.$touch()
      }
    }

    const createOutOfOfficePass = (pass) => {
      state.isLoading = true
      store
        .dispatch("outOfOfficePasses/createOutOfOfficePass", pass)
        .then(() => {
          resetForm()
          setSuccessResponse("Successfully created.")
          state.isLoading = false
          modal.setAction("createOutOfOffice")
          setTimeout(() => {
            resetResponseMessages()
            emit("cancel")
          }, 1000)
        })
        .catch((err) => {
          const response = err.response.data
          setErrorResponse(response.message, response.errors)
          state.isLoading = false
        })
    }

    const editOutOfOffice = (id, data) => {
      store
        .dispatch("outOfOfficePasses/editOutOfOffice", { id, data })
        .then(() => {
          setSuccessResponse("Successfully updated!")
          state.isLoading = false
          setTimeout(() => {
            cancelEdit()
          }, 1200)
        })
        .catch((err) => {
          const response = err.response.data
          setErrorResponse(response.message, response.errors)
          state.isLoading = false
        })
    }

    const cancelEdit = () => {
      resetResponseMessages()
      resetForm()
      emit("cancel")
    }

    const setFormByPassRecord = (pass) => {
      if (pass) {
        const isDefaultUnavailable =
          defaultUnavailable.value &&
          defaultUnavailable.value.id === pass.unavailable_id
        state.form.unavailable = isDefaultUnavailable
          ? defaultUnavailable.value
          : {
              label: pass.unavailable.name,
              value: {
                id: pass.unavailable_id,
                type: pass.unavailable_type
              }
            }
        state.form.start_time = pass.from_date
          ? helpersJS.transformDate(pass.from_date, "HH:mm")
          : null
        state.form.end_time = pass.to_date
          ? helpersJS.transformDate(pass.to_date, "HH:mm")
          : null
        state.form.start_date = pass.from_date ? pass.from_date : null
        state.form.reason = pass.comment
        state.form.presetsTime = null
        if (pass.recurrence_type) {
          state.form.recurrence_type = pass.recurrence_type
          state.form.recurrence_days = pass.recurrence_days
            ? JSON.parse(pass.recurrence_days).days
            : null
          state.form.recurrence_end_at = pass.recurrence_end_at
          state.form.recurrence_week = pass.recurrence_week
        } else {
          state.form.recurrence_type = null
        }
      }
    }

    const setWeekDay = (day) => {
      if (state.form.recurrence_days && state.form.recurrence_days.length) {
        if (state.form.recurrence_days.filter((el) => el === day).length) {
          state.form.recurrence_days = state.form.recurrence_days.filter(
            (el) => el !== day
          )
        } else {
          state.form.recurrence_days.push(day)
        }
      } else {
        state.form.recurrence_days.push(day)
      }
    }

    const formatMontlyWeekDay = (date) => {
      if (date) {
        const dayOfMonth = moment.utc(date).format("DD")
        const dayOfweek = moment.utc(date).format("dddd")
        const numberofweek = Math.floor((dayOfMonth - 1) / 7) + 1
        let prefix = ""
        switch (numberofweek) {
          case 1:
            prefix = "first"
            break
          case 2:
            prefix = "second"
            break
          case 3:
            prefix = "third"
            break
          case 4:
            prefix = "fourth"
            break
          default:
            prefix = "last"
        }
        state.form.recurrence_week =
          '{"' + numberofweek + '":"' + dayOfweek + '"}'
        state.repeatOptions[3].label =
          "Monthly on the " + prefix + " " + dayOfweek
      }
    }

    const resetRepaetOptions = () => {
      state.form.recurrence_days = []
      state.form.recurrence_end_at = null
      state.form.recurrence_week = null
      formatMontlyWeekDay(state.form.start_date)
    }

    const calculateAndSetEndTime = (startTime) => {
      const endTime = state.form.presetsTime
        ? helpersJS.addMinutesToTime(startTime, state.form.presetsTime.value)
        : null
      state.form.end_time = endTime ? endTime : state.form.end_time
    }

    const calculateAndSetStartTime = (endTime) => {
      const startTime = state.form.presetsTime
        ? helpersJS.removeMinutesFromTime(endTime, state.form.presetsTime.value)
        : null
      state.form.start_time = startTime ? startTime : state.form.start_time
    }

    const changePresetsTime = (value) => {
      state.form.presetsTime = value
      if (state.form.start_time) {
        calculateAndSetEndTime(state.form.start_time)
      }
    }

    const resetForm = () => {
      v$.value.$reset()
      state.form = {
        unavailable: defaultUnavailable.value,
        start_date: state.today,
        start_time: moment().format("HH:mm"),
        end_time: null,
        reason: "",
        recurrence_type: state.repeatOptions[0].value,
        recurrence_days: [],
        recurrence_end_at: null,
        presetsTime: state.presetsOptions[1]
      }
      calculateAndSetEndTime(state.form.start_time)
    }

    const resetResponseMessages = () => {
      state.reqResponse = {
        type: "",
        message: "",
        errors: {}
      }
    }

    const setSuccessResponse = (message) => {
      state.reqResponse = {
        type: "success",
        message: message ? message : "Success!",
        errors: {}
      }
    }

    const setErrorResponse = (message, errors) => {
      state.reqResponse = {
        type: "danger",
        message: message ? message : "Something went wrong!",
        errors: errors ? errors : {}
      }
    }

    const goToFullMenu = () => {
      emit("cancel")
    }

    return {
      state,
      props,
      errors,
      isValid,
      userRole,
      defaultUnavailable,
      today,
      maxDate,
      submit,
      cancelEdit,
      setWeekDay,
      formatMontlyWeekDay,
      resetRepaetOptions,
      calculateAndSetEndTime,
      calculateAndSetStartTime,
      changePresetsTime,
      goToFullMenu
    }
  }
}
</script>

<style lang="scss">
.vs--disabled .vs__clear,
.vs--disabled .vs__dropdown-toggle,
.vs--disabled .vs__open-indicator,
.vs--disabled .vs__search,
.vs--disabled .vs__selected {
  .vs__actions {
    display: none !important;
  }
  cursor: pointer !important;
}
</style>
