<template>
  <form
    v-if="data.form !== null"
    class="d-flex flex-column flex-wrap gap-4 w-100"
    @submit.prevent="onDetailFormSubmit"
  >
    <!-- SERVER ERROR -->
    <VisitorErrorHandler
      v-if="serverError != null"
      :error="serverError"
      @done="serverError = null"
    />
    <!-- SERVER ERROR -->

    <!-- FORM HEADER -->
    <div>Visitor check-in</div>
    <!-- FORM HEADER -->

    <!-- FORM CONTENT - FORM FIELD + TAKE PHOTO -->
    <div
      class="d-flex justify-content-between align-content-start flex-wrap gap-4"
    >
      <!-- FORM FIELDS -->
      <div
        class="d-flex justify-content-start align-content-center flex-wrap gap-4"
        :class="{
          'w-100': !isPhotoCaptureSettingEnabled,
          'w-75': isPhotoCaptureSettingEnabled
        }"
      >
        <div
          class="d-flex flex-column justify-content-center align-content-center flex-wrap"
        >
          <Label>Reason for visit</Label>
          <CustomSelect
            class="w-350px"
            :close-on-select="true"
            placeholder="Reason for visit"
            v-model="data.form.reasonForVisit"
            @update:model-value="onReasonForVisitChange"
            :options="reasonForVisitOptions"
          />
        </div>

        <div
          v-if="isStudentPickUpOrDropOffReasonSelected"
          class="d-flex flex-column justify-content-center align-content-center"
        >
          <Label>{{ pickUpOrDropOffLabel }}</Label>
          <CustomSelect
            class="w-350px"
            :class="{
              'visitor-loading': reasonLookupsOptions.length === 0
            }"
            :close-on-select="true"
            placeholder="Select an option"
            v-model="data.form.lookUp"
            :options="reasonLookupsOptions"
            @update:model-value="onReasonLookUpChange"
          />
        </div>

        <div
          v-if="isOtherReasonSelected"
          class="d-flex flex-column justify-content-center align-content-center"
        >
          <Label>Other reason</Label>
          <StrictInputField
            class="w-350px"
            placeholder="Enter the reasons"
            v-model="data.form.otherReason"
          />
        </div>
        <div
          v-if="showDestinationSelectInput"
          class="d-flex flex-column justify-content-center align-content-center"
        >
          <Label>Destination</Label>
          <CustomSelect
            class="w-350px"
            :class="{
              'visitor-loading': destinationOptions.length === 0
            }"
            :close-on-select="true"
            placeholder="Choose destination"
            v-model="data.form.destination"
            :options="destinationOptions"
            @update:model-value="onDestinationChange"
          />
        </div>

        <div
          v-if="isOtherDestinationSelected"
          class="d-flex flex-column justify-content-center align-content-center"
        >
          <Label>Other destination</Label>
          <StrictInputField
            class="w-350px"
            placeholder="Enter the destination"
            v-model="data.form.otherDestination"
          />
        </div>

        <VisitorSearchField
          :search-user-placeholder="searchUserPlaceholder"
          :is-sis-setting-enabled="isSisSettingEnabled"
          :is-student-pick-up-or-drop-off-reason-selected="
            isStudentPickUpOrDropOffReasonSelected
          "
          @on-value-change="onVisitorSearchFieldChange"
          @on-error="
            (value) => {
              serverError = value
            }
          "
        />

        <div
          class="d-flex flex-column justify-content-center align-content-center"
        >
          <Label>First name</Label>
          <InputField
            class="w-350px"
            placeholder="Enter first name"
            v-model="data.form.firstName"
            @update:model-value="onNameFieldChange"
          />
        </div>

        <div
          class="d-flex flex-column justify-content-center align-content-center"
        >
          <Label>Last name</Label>
          <InputField
            class="w-350px"
            placeholder="Enter last name"
            v-model="data.form.lastName"
            @update:model-value="onNameFieldChange"
          />
        </div>

        <div
          class="d-flex flex-column justify-content-center align-content-center"
        >
          <Label>Date of birth</Label>
          <div class="w-350px">
            <CustomDatePicker
              :key="getDobKey()"
              input-class="d-block"
              v-model="data.form.dateOfBirth"
              :masks="{ L: 'MM/DD/YYYY' }"
              placeholder="Date of birth"
              :max-date="new Date()"
            />
          </div>
        </div>

        <div
          v-if="isPhoneNumberEntrySettingEnabled"
          class="d-flex flex-column justify-content-center align-content-center"
        >
          <Label>Phone number</Label>
          <PhoneInput
            class="w-350px"
            placeholder="Enter phone number"
            v-model="data.form.phoneNumber"
          />
        </div>

        <div
          v-if="
            isStudentPickUpOrDropOffReasonSelected &&
            hasVisitorFirstNameOrLastName
          "
          class="d-flex flex-column justify-content-center align-content-center"
        >
          <Label>Student name(s)</Label>
          <VisitorStudentSelect
            class="w-350px"
            :key="data.form.firstName + data.form.lastName"
            :visitor-id="data?.form?.visitor?.id"
            :visitor-first-name="data.form.firstName"
            :visitor-last-name="data.form.lastName"
            :model-value="data.form.students"
            @input="onStudentSelectionChange"
          />
        </div>
      </div>
      <!-- FORM FIELDS -->

      <!-- TAKE PHOTO FIELD -->
      <div v-if="isPhotoCaptureSettingEnabled" class="visitor-photo-root">
        <VisitorTakePhoto
          v-if="hasNoProfileImageAndImageData"
          @capture="onImageCapture"
          @notify="$emit('onNotify', $event)"
        />
        <img
          v-else
          :src="data?.form?.imageData || data?.form?.profileImage || ''"
          class="visitor-check-in-captured-image"
          alt="Profile Image"
        />
        <VisitorCaptureViaMenu @notify="$emit('onNotify', $event)" />
      </div>
      <!-- TAKE PHOTO FIELD -->
    </div>
    <!-- FORM CONTENT - FORM FIELD + TAKE PHOTO -->

    <!-- FORM FOOTER -->
    <div class="w-100 d-flex gap-4">
      <BaseButton
        type="button"
        rounded
        @click="onFormReset"
        :disabled="isClearAllDisabled"
      >
        Clear all
      </BaseButton>
      <LoadingButton
        type="submit"
        :is-loading="data?.form?.isLoading || isLoading"
        prepend-icon="ri-login-box-line"
        solid
        rounded
        :disabled="isButtonDisabled"
      >
        {{ buttonActionText }}
      </LoadingButton>
    </div>
    <!-- FORM FOOTER -->
  </form>
</template>

<script>
import VisitorErrorHandler from "@/v3components/VisitorErrorHandler.vue"
import BaseButton from "@/v3components/shared/Buttons/BaseButton.vue"
import VisitorCaptureViaMenu from "@/v3components/Forms/Visitor/CheckIns/VisitorCaptureViaMenu.vue"
import LoadingButton from "@/v3components/shared/Buttons/LoadingButton.vue"
import CustomSelect from "@/v3components/shared/Form/CustomSelect.vue"
import Label from "@/v3components/shared/Form/Label.vue"
import CustomDatePicker from "@/v3components/shared/Form/CustomDatePicker.vue"
import VisitorSearchField from "@/v3components/Forms/Visitor/CheckIns/VisitorSearchField.vue"
import StrictInputField from "@/v3components/shared/Form/StrictInputField.vue"
import InputField from "@/v3components/shared/Form/InputField.vue"
import VisitorTakePhoto from "@/v3components/Forms/Visitor/CheckIns/VisitorTakePhoto.vue"
import VisitorStudentSelect from "@/v3components/Forms/Visitor/CheckIns/VisitorStudentSelect.vue"
import PhoneInput from "@/v3components/shared/Form/PhoneInput.vue"
import visitorConstants from "@/constants/visitorConstants"
import moment from "moment-timezone"
import passHelpers from "@/helpers/index"
import { computed, onMounted, reactive, ref, watch } from "vue"
import { useStore } from "vuex"

export default {
  name: "VisitorDetailForm",
  components: {
    Label,
    BaseButton,
    LoadingButton,
    CustomSelect,
    InputField,
    CustomDatePicker,
    StrictInputField,
    VisitorTakePhoto,
    PhoneInput,
    VisitorSearchField,
    VisitorStudentSelect,
    VisitorErrorHandler,
    VisitorCaptureViaMenu
  },
  props: {
    form: {
      type: Object,
      default: () => ({})
    },
    onFormSubmit: {
      type: Function,
      default: () => ({})
    },
    isClearAllDisabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ["onFormReset", "onNotify"],
  setup(props, { emit }) {
    const store = useStore()

    const isLoading = ref(false)
    const serverError = ref(null)

    const data = reactive({
      form: null
    })

    const getDobKey = () => {
      const date = new Date(data?.form?.dateOfBirth).getTime()
      const random = Math.floor(Math.random() * 1000)
      return `${date}-${random}`
    }

    const school = computed(() => store.getters["visitorSettings/school"])

    const visitorSchoolData = computed(() => {
      return school?.value?.data || null
    })

    const destinationSetting = computed(() => {
      return (
        visitorSchoolData.value?.building_settings?.find(
          (setting) =>
            setting?.settings_id === visitorConstants.SETTINGS.DESTINATION
        ) || null
      )
    })

    const isDestinationSettingEnabled = computed(() => {
      return destinationSetting?.value?.value === "true" || false
    })

    const photoCaptureSetting = computed(() => {
      return (
        visitorSchoolData.value?.building_settings?.find(
          (setting) =>
            setting?.settings_id === visitorConstants.SETTINGS.PHOTO_CAPTURE
        ) || null
      )
    })

    const isPhotoCaptureSettingEnabled = computed(() => {
      return photoCaptureSetting?.value?.value === "true" || false
    })

    const sisSetting = computed(() => {
      return (
        visitorSchoolData.value?.building_settings?.find(
          (setting) => setting?.settings_id === visitorConstants.SETTINGS.SIS
        ) || null
      )
    })

    const isSisSettingEnabled = computed(() => {
      return sisSetting?.value?.value === "true" || false
    })

    const phoneNumberEntrySetting = computed(() => {
      return (
        visitorSchoolData.value?.building_settings?.find(
          (setting) =>
            setting?.settings_id ===
            visitorConstants.SETTINGS.PHONE_NUMBER_ENTRY
        ) || null
      )
    })

    const isPhoneNumberEntrySettingEnabled = computed(() => {
      return phoneNumberEntrySetting?.value?.value === "true" || false
    })

    const healthScreeningSetting = computed(() => {
      return (
        visitorSchoolData.value?.building_settings?.find(
          (setting) =>
            setting?.settings_id === visitorConstants.SETTINGS.HEALTH_SCREENING
        ) || null
      )
    })

    const isHealthScreeningEnabled = computed(() => {
      return healthScreeningSetting?.value?.value === "true" || false
    })

    const reasonLookups = computed(() => {
      return (
        store.getters["visitorManage/reasonLookups"]?.filter(
          (reasonLookUp) => Number(reasonLookUp?.status) === 1
        ) || []
      )
    })

    const reasons = computed(() => store.getters["visitorManage/reasons"])

    const destinations = computed(
      () => store.getters["visitorManage/destinations"]
    )

    const reasonForVisitOptions = computed(() => {
      return (
        reasons?.value?.existing
          ?.filter((reason) => Number(reason?.status) === 1)
          ?.filter(
            (reason) =>
              reason?.lookup_value?.trim()?.length > 0 &&
              reason?.lookup_code?.trim()?.length > 0
          )
          .map((reason) => {
            return {
              label: reason?.lookup_value || "",
              value: reason
            }
          }) || []
      )
    })

    const reasonLookup = computed(() => {
      const hasReasonForVisit =
        data.form.reasonForVisit?.value?.lookup_code?.trim()?.length > 0

      if (!hasReasonForVisit) {
        return null
      }

      const lookUp = reasonLookups?.value?.find(
        (lookup) =>
          lookup?.lookup_code === data.form.reasonForVisit?.value?.lookup_code
      )
      return lookUp || null
    })

    const reasonLookupsOptions = computed(() => {
      const lookUp = reasonLookup?.value
      if (!lookUp) {
        return []
      }

      const options =
        lookUp?.sub_reasons?.map((subReason) => {
          return {
            label: subReason?.lookup_value || "",
            value: subReason
          }
        }) || []

      return options
    })

    const isOtherReasonSelected = computed(() => {
      return (
        data.form.reasonForVisit?.value?.lookup_code
          ?.toString()
          ?.toUpperCase()
          ?.includes("OTHERS") ||
        data.form.lookUp?.value?.lookup_code
          ?.toString()
          ?.toUpperCase()
          ?.includes("OTHER")
      )
    })

    const isStudentPickUpOrDropOffReasonSelected = computed(() => {
      return (
        data.form.reasonForVisit?.value?.lookup_code === "PICK_UP" ||
        data.form.reasonForVisit?.value?.lookup_code === "DROP_OFF"
      )
    })

    const pickUpOrDropOffLabel = computed(() => {
      const map = {
        PICK_UP: "Pick-up option",
        DROP_OFF: "Drop-off option"
      }
      return map[data.form.reasonForVisit?.value?.lookup_code] || ""
    })

    const destinationOptions = computed(() => {
      return (
        destinations?.value?.destinations?.map((dest) => {
          return {
            label: dest?.destination || "",
            value: dest
          }
        }) || []
      )
    })

    const showDestinationSelectInput = computed(() => {
      if (!isDestinationSettingEnabled.value) {
        return false
      }
      if (isStudentPickUpOrDropOffReasonSelected.value) {
        return false
      }
      return true
    })

    const isOtherDestinationSelected = computed(() => {
      return data.form.destination?.value?.destination
        ?.toString()
        ?.toUpperCase()
        ?.includes("OTHERS")
    })

    const searchUserPlaceholder = computed(() => {
      const map = {
        PICK_UP: "Search visitor/student name or phone number",
        DROP_OFF: "Search visitor/student name or phone number",
        VISIT_WITH_TEACHER: "Search visitor name",
        OTHERS: "Search visitor name"
      }
      return (
        map[data.form.reasonForVisit?.value?.lookup_code] ||
        "Search visitor name"
      )
    })

    const hasProfileImage = computed(() => {
      return data.form?.profileImage?.trim()?.length > 0
    })

    const hasImageData = computed(() => {
      return !!data.form.imageData
    })

    const hasNoProfileImageAndImageData = computed(() => {
      return !hasProfileImage.value && !hasImageData.value
    })

    const isFormValid = computed(() => {
      const hasFirstName = data.form?.firstName?.trim().length > 0
      const hasLastName = data.form?.lastName?.trim().length > 0
      const hasDateOfBirth = moment(data.form?.dateOfBirth).isValid()
      const hasOtherReason = data.form?.otherReason?.trim().length > 0
      const hasDestination = showDestinationSelectInput.value
        ? data.form?.destination?.value?.destination?.trim().length > 0
        : true
      const hasOtherDestination = isOtherDestinationSelected.value
        ? data.form?.otherDestination?.trim().length > 0
        : true
      const hasReasonForVisit =
        data.form?.reasonForVisit?.value?.lookup_code?.trim().length > 0
      const hasPickUpOrDropOffIdValue =
        data.form?.lookUp?.value?.lookup_value?.trim().length > 0
      const hasPickUpOrDropOffTextValue =
        data.form?.lookUp?.value?.lookup_value?.trim().length > 0
      const hasPhoneNumber = isPhoneNumberEntrySettingEnabled.value
        ? data.form?.phoneNumber?.trim()?.length === 14
        : true
      const hasStudents = data.form?.students?.length > 0

      if (isStudentPickUpOrDropOffReasonSelected.value) {
        return (
          hasReasonForVisit &&
          hasPickUpOrDropOffIdValue &&
          hasPickUpOrDropOffTextValue &&
          hasFirstName &&
          hasLastName &&
          hasDateOfBirth &&
          hasPhoneNumber &&
          hasStudents
        )
      }

      if (isOtherReasonSelected.value) {
        return (
          hasReasonForVisit &&
          hasOtherReason &&
          hasDestination &&
          hasOtherDestination &&
          hasFirstName &&
          hasLastName &&
          hasDateOfBirth &&
          hasPhoneNumber
        )
      }

      return (
        hasReasonForVisit &&
        hasDestination &&
        hasOtherDestination &&
        hasFirstName &&
        hasLastName &&
        hasDateOfBirth &&
        hasPhoneNumber
      )
    })

    const hasVisitorFirstNameOrLastName = computed(() => {
      return (
        data.form?.firstName?.toString()?.trim()?.length > 0 &&
        data.form?.lastName?.toString()?.trim()?.length > 0
      )
    })

    const shouldGoForHealthCheck = computed(() => {
      return (
        isHealthScreeningEnabled.value &&
        Number(data?.form?.visitor?.health_check_required) === 1
      )
    })

    const buttonActionText = computed(() => {
      return shouldGoForHealthCheck.value ? "Continue" : "Check in"
    })

    const isButtonDisabled = computed(() => {
      return !isFormValid.value
    })

    const onReasonForVisitChange = () => {
      data.form.lookUp = null
      data.form.destination = null
      data.form.otherReason = ""
      data.form.otherDestination = ""
    }

    const onReasonLookUpChange = () => {
      data.form.otherReason = ""
    }

    const onDestinationChange = () => {
      data.form.otherDestination = ""
    }

    const onDetailFormSubmit = async () => {
      const isFormInvalid = !isFormValid.value
      if (isFormInvalid) return

      isLoading.value = true
      await props.onFormSubmit(data.form)
      isLoading.value = false
    }

    const onFormReset = () => {
      data.form = null
      emit("onFormReset")
    }

    const onImageCapture = (value) => {
      data.form.imageData = value
    }

    const onNameFieldChange = () => {
      data.form.students = []
      data.form.profileImage = ""
      data.form.imageData = null
    }

    const sanitizeDate = (date) => {
      const isValid = moment(date).isValid()
      const dateOfBirth = isValid
        ? passHelpers.convertToCurrentTimezone(date, false, "MM-DD-YYYY", true)
        : null
      return dateOfBirth
    }

    const onVisitorSearchFieldChange = ({ value }) => {
      if (!value) {
        return
      }

      const date = value?.date_of_birth || value?.profile?.date_of_birth || ""

      data.form.firstName =
        value?.first_name ||
        value?.parent_first_name ||
        value?.profile?.first_name ||
        ""
      data.form.lastName =
        value?.last_name ||
        value?.parent_last_name ||
        value?.profile?.last_name ||
        ""
      data.form.dateOfBirth = sanitizeDate(date)
      const phoneNumber =
        value?.phone_number || value?.profile?.phone_number || ""
      data.form.phoneNumber = phoneNumber
        ? passHelpers.phoneNumberFormatter(phoneNumber)
        : ""
      data.form.profileImage =
        value?.profile_image_uri ||
        value?.profile_image_thumbnail_uri ||
        value?.profile?.profile_image_thumbnail_uri ||
        ""
      data.form.sourcedId = value?.sourceddid || ""
      if (isStudentPickUpOrDropOffReasonSelected.value) {
        data.form = {
          ...data.form,
          students: [
            ...(value?.students?.split(",").map((name) => {
              const [studentId, firstName, lastName] = name.split("|")
              return {
                student_id: studentId || "",
                first_name: firstName?.trim() || "",
                last_name: lastName?.trim() || "",
                guardians: [
                  {
                    firstName: value?.parent_first_name || "",
                    lastName: value?.parent_last_name || "",
                    phoneNumber: phoneNumber
                      ? passHelpers.phoneNumberFormatter(phoneNumber)
                      : ""
                  }
                ]
              }
            }) || [])
          ]
        }
      } else {
        data.form.students = []
      }
    }

    const onStudentSelectionChange = (value) => {
      data.form.students = value
    }

    onMounted(() => {
      data.form = props.form
    })

    watch(
      () => props.form,
      (value) => {
        data.form = value
      }
    )

    return {
      data,
      getDobKey,
      isLoading,
      serverError,
      hasVisitorFirstNameOrLastName,
      showDestinationSelectInput,
      isPhotoCaptureSettingEnabled,
      isPhoneNumberEntrySettingEnabled,
      isHealthScreeningEnabled,
      isSisSettingEnabled,
      hasNoProfileImageAndImageData,
      reasonForVisitOptions,
      reasonLookupsOptions,
      isOtherReasonSelected,
      isStudentPickUpOrDropOffReasonSelected,
      pickUpOrDropOffLabel,
      isFormValid,
      destinationOptions,
      isOtherDestinationSelected,
      searchUserPlaceholder,
      buttonActionText,
      isButtonDisabled,
      onNameFieldChange,
      onReasonForVisitChange,
      onReasonLookUpChange,
      onDestinationChange,
      onStudentSelectionChange,
      onImageCapture,
      onDetailFormSubmit,
      onFormReset,
      onVisitorSearchFieldChange
    }
  }
}
</script>

<style scoped>
.w-350px {
  width: 350px;
}

.w-60px {
  width: 60px;
}
</style>
