<template>
  <CRow class="justify-content-center p-5">
    <CCol md="9 d-flex flex-column">
      <h2 class="text-center mb-3 mt-0">Check-In</h2>
      <InputField
        v-model="state.roomPin"
        placeholder="Enter room PIN"
        :invalid-feedback="
          errors.roomPin ||
          (state.responseMessageRoomApproval &&
            state.responseMessageRoomApproval.message)
        "
        type="text"
        autocomplete="off"
        @input="state.responseMessageRoomApproval = null"
      >
      </InputField>
      <div class="d-flex flex-wrap w-100 stuff-only-popup-buttons">
        <button
          class="btn btn-comfirm custom-w-h-btn m-2 p-1"
          @click="autoCheckIn()"
        >
          CHECK-IN
        </button>
      </div>
    </CCol>
  </CRow>
</template>

<script>
import { reactive } from "vue"
import { useVuelidate } from "@vuelidate/core"
import { helpers, required, maxLength, minLength } from "@vuelidate/validators"
import InputField from "@/v3components/shared/Form/InputField"
import { computed } from "vue"
import { useStore } from "vuex"

export default {
  name: "AdultAptPassCheckInModal",
  components: {
    InputField
  },
  props: {
    activePass: {
      type: Object,
      required: true
    }
  },
  emits: ["cancel"],
  setup(props, { emit }) {
    const store = useStore()

    const state = reactive({
      roomPin: "",
      responseMessageRoomApproval: null
    })

    const validationMessages = {
      required: "This field is required",
      maxLength: "The pin may not be greater than 6 characters",
      minLength: "The pin should be at least 4 characters"
    }

    const validations = {
      roomPin: {
        minLength: helpers.withMessage(
          validationMessages.minLength,
          minLength(4)
        ),
        maxLength: helpers.withMessage(
          validationMessages.maxLength,
          maxLength(6)
        ),
        required: helpers.withMessage(validationMessages.required, required)
      }
    }

    const v$ = useVuelidate(validations, state)

    const isValid = computed(() => !v$.value.$invalid)

    const errors = computed(() => {
      const errorObj = {}
      v$.value.$errors.forEach((err) => {
        errorObj[err.$property] = err.$message
      })
      return errorObj
    })

    const autoCheckIn = () => {
      if (isValid.value) {
        store
          .dispatch("passes/autoCheckIn", {
            id: props.activePass.id,
            roomPin: state.roomPin
          })
          .then(() => {
            state.roomPin = null
            v$.value.$reset()
            emit("cancel")
          })
          .catch((err) => {
            state.responseMessageRoomApproval = {
              type: "danger",
              message: err.response.data.message
            }
            const locked = err.response.data.data.locked
            if (locked) {
              store
                .dispatch("authentication/getUserDetails", {
                  clearCacheEntry: true
                })
                .then(() => {
                  window.location.reload()
                })
            }
          })
      } else {
        v$.value.$touch()
      }
    }

    return {
      state,
      errors,
      autoCheckIn
    }
  }
}
</script>

<style></style>
