<template>
  <div class="visitor-check-in-photo-container" @click="openModal">
    <div class="d-flex flex-column">
      <i class="ri-camera-2-line"></i>
      <span>Take photo</span>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent, inject, ref } from "vue"
import visitorConstants from "@/constants/visitorConstants"
const VisitorPhotoCapturePopUp = defineAsyncComponent(
  () =>
    import("@/v3components/Forms/Visitor/CheckIns/VisitorPhotoCapturePopUp.vue")
)

export default {
  name: "VisitorTakePhoto",
  emits: ["capture", "notify"],
  setup(_, { emit }) {
    const modal = inject("modal")
    const imageData = ref(null)

    const openModal = () => {
      const camera =
        localStorage?.getItem(
          visitorConstants.VISITOR_LOCAL_STORAGE_KEYS.DEFAULT_CAMERA
        ) || visitorConstants.VISITOR_CAMERA_TYPES.COMPUTER

      if (
        [
          visitorConstants.VISITOR_CAMERA_TYPES.IOS,
          visitorConstants.VISITOR_CAMERA_TYPES.ANDROID
        ].includes(camera)
      ) {
        emit("notify", camera)
        return
      }

      modal.open(VisitorPhotoCapturePopUp, {
        size: "sm",
        title: visitorConstants.CHECK_IN_FORM.MODAL_TITLE_TAKE_PHOTO,
        props: {
          onCapture: (data) => {
            imageData.value = data
            emit("capture", data)
          }
        }
      })
    }

    return {
      openModal
    }
  }
}
</script>

<style scoped></style>
