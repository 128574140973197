<template>
  <div class="container">
    <div class="row justify-content-center">
      <div
        class="verify-email-body col-sm-10 col-md-8 col-xl-6 col-12 max-w-620 m-auto p-4 text-center"
      >
        <div class="row max-w-550 m-auto mb-4">
          <div class="col-12 mb-4">
            <img
              src="@/assets/images/securlypass-color-logo.png"
              alt="securlypass"
              class="w-50"
            />
          </div>
          <div class="col-12 p-0">
            <div class="verify-email-heading">Email Verification</div>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col">
            <Label>Enter verification code</Label>
            <InputField
              placeholder="Enter verification code"
              v-model="state.verificationCode"
              :invalid-feedback="errors.verificationCode"
            />
            <InfoBox
              v-if="state.serverRes"
              class="mt-4"
              :class="{ danger: Object.keys(state.serverErrors).length }"
              :title="state.serverRes.title"
              >{{ state.serverRes.message }}</InfoBox
            >
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <LoadingButton
            :is-loading="state.isLoading"
            class="d-flex align-items-center justify-content-center me-3"
            solid
            rounded
            @click="verify"
            >Verify</LoadingButton
          >
          <LoadingButton
            class="d-flex align-items-center justify-content-center"
            rounded
            @click="cancel"
            >Cancel</LoadingButton
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Label from "@/v3components/shared/Form/Label.vue"
import InputField from "@/v3components/shared/Form/InputField.vue"
import InfoBox from "@/v3components/shared/Alerts/InfoBox"
import LoadingButton from "@/v3components/shared/Buttons/LoadingButton"
import { reactive, computed, onMounted } from "vue"
import { useRouter, useRoute } from "vue-router"
import { useStore } from "vuex"
import useVuelidate from "@vuelidate/core"
import { helpers, required } from "@vuelidate/validators"

export default {
  name: "VerifyEmailForm",
  components: { Label, InputField, InfoBox, LoadingButton },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const state = reactive({
      isLoading: false,
      verificationCode: "",
      serverRes: null,
      serverErrors: {}
    })

    onMounted(() => {
      if (route.query && route.query.verificationCode) {
        setTimeout(() => {
          state.verificationCode = route.query.verificationCode
          verify()
        }, 250)
      }
    })

    const verify = () => {
      state.serverRes = null
      state.serverErrors = {}
      state.isLoading = true
      if (isValid.value) {
        store
          .dispatch("authentication/verifyEmailAddress", {
            verificationCode: state.verificationCode
          })
          .then((response) => {
            state.isLoading = false
            const res = response.data.data
            state.serverRes = {
              title: res.status ? "Success" : "Error",
              message: res.message
            }
            if (res.status) {
              setTimeout(() => {
                router.push("/login")
              }, 1500)
            } else {
              state.serverErrors = { error: "error" }
            }
          })
          .catch((err) => {
            state.isLoading = false
            state.serverErrors = { error: "error" }
            state.serverRes = {
              title: "Error",
              message: err.message
            }
          })
      } else {
        state.isLoading = false
        v$.value.$touch()
      }
    }

    const cancel = () => {
      router.push("/login")
    }

    const validationMessages = {
      required: "This field is required"
    }

    const validations = {
      verificationCode: {
        required: helpers.withMessage(validationMessages.required, required)
      }
    }

    const v$ = useVuelidate(validations, state)

    const isValid = computed(() => !v$.value.$invalid)

    const errors = computed(() => {
      const errorObj = {}
      v$.value.$errors.forEach((err) => {
        errorObj[err.$property] = err.$message
      })

      return errorObj
    })

    return { state, verify, cancel, isValid, errors }
  }
}
</script>
