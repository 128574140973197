<template>
  <div
    v-if="data.form !== null"
    class="d-flex justify-content-center align-content-center py-4"
  >
    <template v-if="!isImageLoading">
      <div class="d-flex flex-column gap-4 text-center">
        <!-- SERVER ERROR -->
        <VisitorErrorHandler
          v-if="serverError != null"
          whitespace
          :error="serverError"
          @done="serverError = null"
        />
        <!-- SERVER ERROR -->

        <div class="d-flex flex-column gap-4">
          <div class="d-flex justify-content-center align-content-center">
            <i class="visitor-check-in-success ri-checkbox-circle-line"></i>
          </div>
          <h5>
            {{
              data?.form?.firstName ||
              data?.form?.visitor?.first_name ||
              "Visitor"
            }}
            has been successfully checked in
          </h5>
        </div>
        <div class="d-flex justify-content-center gap-4">
          <BaseButton rounded @click="onPrintBadgeBtnClick">
            Print badge
          </BaseButton>
          <BaseButton solid rounded @click="onFormReset">
            Return to check in
          </BaseButton>
        </div>
      </div>
    </template>
    <div
      v-else
      class="d-flex justify-content-center align-content-center gap-2"
    >
      <CSpinner color="primary" />
    </div>
  </div>
</template>

<script>
import BaseButton from "@/v3components/shared/Buttons/BaseButton.vue"
import VisitorErrorHandler from "@/v3components/VisitorErrorHandler.vue"
import visitorConfigs from "@/configs/visitorConfigs"
import visitorConstants from "@/constants/visitorConstants"
import passHelpers from "@/helpers/index"
import moment from "moment-timezone"
import { generateMarkupAndStartPrint } from "@/helpers/printerService.js"
import { computed, onMounted, watch, ref, reactive, onUnmounted } from "vue"
import { useRouter } from "vue-router"
import { useStore } from "vuex"

export default {
  name: "VisitorPrintForm",
  components: {
    BaseButton,
    VisitorErrorHandler
  },
  props: {
    form: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ["onResetForm", "onBrotherPrinterPrint"],
  setup(props, { emit }) {
    const store = useStore()
    const router = useRouter()

    const data = reactive({
      form: null
    })

    const serverError = ref(null)
    const isImageLoading = ref(true)
    const timeoutId = ref(null)

    const activeSchool = computed(
      () => store?.getters?.["schools/activeSchool"] || null
    )

    const schoolName = computed(() => activeSchool?.value?.name || "")

    const printer = computed(() => store.getters["visitorSettings/printer"])

    const hasNoPrinter = computed(
      () => store.getters["visitorSettings/hasNoPrinter"]
    )

    const school = computed(() => store.getters["visitorSettings/school"])

    const visitorSchoolData = computed(() => {
      return school?.value?.data || null
    })

    const destinationSetting = computed(() => {
      return (
        visitorSchoolData.value?.building_settings?.find(
          (setting) =>
            setting?.settings_id === visitorConstants.SETTINGS.DESTINATION
        ) || null
      )
    })

    const isDestinationEnabled = computed(() => {
      return destinationSetting?.value?.value === "true" || false
    })

    const isStudentPickUpOrDropOffReasonSelected = computed(() => {
      return (
        ["PICK_UP", "DROP_OFF"].includes(
          data?.form?.reasonForVisit?.value?.lookup_code
            ?.trim()
            ?.toUpperCase() || ""
        ) || false
      )
    })

    const onFormReset = () => {
      emit("onResetForm")
    }

    const onPrintBadgeBtnClick = async () => {
      if (hasNoPrinter.value) {
        router.push(visitorConfigs.pages.printerPage().path)
        return
      }

      const payload = {
        firstName:
          data?.form?.firstName || data?.form?.visitor?.first_name || "",
        lastName: data?.form?.lastName || data?.form?.visitor?.last_name || "",
        date: visitorConfigs?.useCustomVisitorTimeFunction
          ? passHelpers.convertToCurrentTimezone(
              moment(),
              false,
              "MMM DD, YYYY - h:mm A"
            )
          : moment().format("MMM DD, YYYY - h:mm A"),
        reasonForVisit: isStudentPickUpOrDropOffReasonSelected?.value
          ? data?.form?.reasonForVisit?.value?.lookup_value
          : data?.form?.otherReason ||
            data?.form?.reasonForVisit?.value?.lookup_value ||
            data?.form?.lookUp?.value?.lookup_value,
        imageURL:
          data?.form?.profileImage ||
          data?.form?.visitor?.profile_image_thumbnail_uri ||
          `${window.location.origin}/img/avatars/user-avatar.jpeg`,
        schoolName: schoolName?.value,
        destination: isDestinationEnabled?.value
          ? data?.form?.otherDestination ||
            data?.form?.destination?.value?.destination ||
            ""
          : ""
      }
      return await initiatePrint(payload)
    }

    const initiatePrint = async ({
      firstName,
      lastName,
      date,
      reasonForVisit,
      imageURL,
      schoolName,
      destination
    }) => {
      const printerBrand =
        printer?.value?.printerBrand?.toString()?.trim()?.toLowerCase() || ""
      if (
        printerBrand?.includes(visitorConstants.PRINTER_CONFIG.BROTHER.BRAND)
      ) {
        emit("onBrotherPrinterPrint", {
          firstName,
          lastName,
          date,
          reasonForVisit,
          imageURL,
          schoolName,
          destination
        })
      } else {
        try {
          const { success, message } = await generateMarkupAndStartPrint({
            firstName,
            lastName,
            date,
            reasonForVisit,
            imageURL,
            schoolName,
            destination
          })
          if (!success) {
            throw new Error(message)
          }
        } catch (error) {
          serverError.value = error
        }
      }
    }

    const preLoadImage = () => {
      const img = new Image()
      img.src =
        data?.form?.profileImage ||
        data?.form?.visitor?.profile_image_thumbnail_uri ||
        "/img/avatars/user-avatar.jpeg"
      img.onload = () => {
        isImageLoading.value = false
      }
      timeoutId.value = setTimeout(() => {
        if (isImageLoading.value) {
          isImageLoading.value = false
        }
      }, 10000)
    }

    onMounted(() => {
      data.form = props.form
      preLoadImage()
    })

    onUnmounted(() => {
      clearTimeout(timeoutId.value)
    })

    watch(
      () => props.form,
      (value) => {
        data.form = value
      }
    )

    return {
      data,
      serverError,
      isImageLoading,
      onPrintBadgeBtnClick,
      onFormReset
    }
  }
}
</script>

<style scoped></style>
