<template>
  <form class="d-flex flex-column gap-4" @submit.prevent="onSubmit">
    <!-- SERVER ERROR -->
    <VisitorErrorHandler
      v-if="serverError != null"
      :error="serverError"
      @done="serverError = null"
    />
    <!-- SERVER ERROR -->

    <!-- FIRST NAME -->
    <div class="d-flex flex-column gap-1">
      <Label required>First Name</Label>
      <InputField
        id="first-name"
        placeholder="First Name"
        v-model="form.firstName"
        :invalid-feedback="errors.firstName"
      />
    </div>
    <!-- FIRST NAME -->

    <!-- LAST NAME -->
    <div class="d-flex flex-column gap-1">
      <Label required>Last Name</Label>
      <InputField
        id="last-name"
        placeholder="Last Name"
        v-model="form.lastName"
        :invalid-feedback="errors.lastName"
      />
    </div>
    <!-- LAST NAME -->

    <!-- SCHOOL EMAIL ADDRESS -->
    <div class="d-flex flex-column gap-1">
      <Label required>School Email Address</Label>
      <InputField
        id="email_address"
        placeholder="Email Address"
        v-model="form.emailAddress"
        :invalid-feedback="errors.emailAddress"
      />
      <Label>
        {{ emailAddressHelperText }}
      </Label>
    </div>
    <!-- SCHOOL EMAIL ADDRESS -->

    <!-- FORM BUTTONS -->
    <div class="d-flex justify-content-center align-content-center gap-4">
      <BaseButton type="button" rounded @click="onCancel">Cancel</BaseButton>
      <LoadingButton type="submit" :is-loading="isLoading" rounded solid>
        Submit
      </LoadingButton>
    </div>
    <!-- FORM BUTTONS -->
  </form>
</template>

<script>
import VisitorErrorHandler from "@/v3components/VisitorErrorHandler.vue"
import BaseButton from "@/v3components/shared/Buttons/BaseButton.vue"
import LoadingButton from "@/v3components/shared/Buttons/LoadingButton.vue"
import InputField from "@/v3components/shared/Form/InputField.vue"
import Label from "@/v3components/shared/Form/Label.vue"
import visitorConstants from "@/constants/visitorConstants"
import useVuelidate from "@vuelidate/core"
import { helpers, maxLength, required } from "@vuelidate/validators"
import { computed, onMounted, ref, reactive } from "vue"
import { useStore } from "vuex"

export default {
  name: "VisitorEditAdultForm",
  components: {
    BaseButton,
    Label,
    InputField,
    LoadingButton,
    VisitorErrorHandler
  },
  props: {
    editUser: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ["cancel"],
  setup(props, { emit }) {
    const store = useStore()

    const form = reactive({
      firstName: "",
      lastName: "",
      emailAddress: ""
    })

    const isLoading = ref(false)
    const serverError = ref(null)

    const rules = {
      firstName: {
        required: helpers.withMessage(
          visitorConstants.VALIDATIONS.REQUIRED,
          required
        )
      },
      lastName: {
        required: helpers.withMessage(
          visitorConstants.VALIDATIONS.REQUIRED,
          required
        )
      },
      emailAddress: {
        required: helpers.withMessage(
          visitorConstants.VALIDATIONS.REQUIRED,
          required
        ),
        maxLength: helpers.withMessage(
          "This field must be at most 255 characters",
          maxLength(255)
        )
      }
    }

    const emailAddressHelperText = computed(() => {
      return `${form.emailAddress.length} / 250`
    })

    const errors = computed(() => ({
      firstName: v$?.value?.firstName?.$error
        ? v$?.value?.firstName?.$errors
            ?.map((error) => error.$message)
            ?.join(", ") || ""
        : "",
      lastName: v$?.value?.lastName?.$error
        ? v$?.value?.lastName?.$errors
            ?.map((error) => error.$message)
            ?.join(", ") || ""
        : "",
      emailAddress: v$?.value?.emailAddress?.$error
        ? v$?.value?.emailAddress?.$errors
            ?.map((error) => error.$message)
            ?.join(", ") || ""
        : ""
    }))

    const v$ = useVuelidate(rules, form)

    const onSubmit = async () => {
      const isFormCorrect = await v$.value.$validate()
      if (!isFormCorrect) {
        return
      }

      try {
        isLoading.value = true
        const payload = {
          user_id: props.editUser.id,
          first_name: form.firstName,
          last_name: form.lastName,
          email_address: form.emailAddress
        }
        await store.dispatch("visitorSettings/updateAdult", payload)
        await store.dispatch("visitorSettings/getAdults")
      } catch (error) {
        serverError.value = error
      } finally {
        isLoading.value = false
      }
    }

    const onCancel = () => {
      emit("cancel")
    }

    onMounted(() => {
      form.firstName = props.editUser.first_name
      form.lastName = props.editUser.last_name
      form.emailAddress = props.editUser.email_address
    })

    return {
      v$,
      form,
      isLoading,
      serverError,
      errors,
      emailAddressHelperText,
      onSubmit,
      onCancel
    }
  }
}
</script>

<style scoped></style>
